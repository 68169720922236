import * as React from "react";
import { Detail } from "../components/settings/Detail";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

export const SettingDetailPage = () => {
  return (
    <div className="grid-layout-container mt-2">
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: 750,
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Detail />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
