import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { SoalBaruFullComponent } from "../components/exam/SoalBaruFullComponent";
import { useGetSettingQuery } from "../features/settingSlice";
import useAuth from "../common/hooks/useAuth";

export const SoalBaruPage = () => {
  const { user } = useAuth();
  const isExpand = useSelector((state) => state.globalState.drawerExpand);
  // console.log("dari lockasi 2", { token: user.accessToken });
  const { data: getSettingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
      type: 'soal_creation',
    } : skipToken
  );
  const userLevel = useSelector((state) => state.globalState.userLevel);
  const hideAttribute =
    get(getSettingData, "hide_attribute", false) && userLevel >= 100;

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 expand"
          : "grid-layout-container mt-2"
      }
    >
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          {!hideAttribute && <SoalBaruFullComponent />}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
