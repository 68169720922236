import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const soalPaketSlice = createApi({
  reducerPath: 'soalPaketApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/soal-paket` }),
  tagTypes: ['soal_paket'],
  endpoints: (builder) => ({
    generatePaketBaru: builder.mutation({
      query: ({ namaPaket, format, mataPelajaran, acak, jumlahSoal, token }) => ({
        url: '/generate-paket-baru',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { namaPaket, format, mataPelajaran, acak, jumlahSoal, token },
      }),
      invalidatesTags: ['soal_paket'],
    }),
    createPaket: builder.mutation({
      query: ({ namaPaket, mataPelajaran, soals, token }) => ({
        url: '/create-paket',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { namaPaket, mataPelajaran, soals },
      }),
      invalidatesTags: ['soal_paket'],
    }),
    deletePaket: builder.mutation({
      query: ({ namaPaket, token }) => ({
        url: '/delete',
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { namaPaket },
      }),
      invalidatesTags: ['soal_paket'],
    }),
    queryPaket: builder.query({
      query: ({ namaPaket, mataPelajaran, token }) => ({
        url: '/query',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { namaPaket, mataPelajaran, token },
      }),
      invalidatesTags: ['soal_paket'],
    }),

  }),
});

export const {
  useGeneratePaketBaruMutation,
  useCreatePaketMutation,
  useDeletePaketMutation,
  useLazyQueryPaketQuery,
} = soalPaketSlice;