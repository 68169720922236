import { useState } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  usePreviewBatchDeleteFileBlobMutation,
  useUploadBatchDeleteFileBlobMutation,
} from "../../features/userSlice";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { BatchDeleteListing } from "./BatchDeleteListing";

export const BatchDelete = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [previewBatchDeleteFileBlob, { isLoading: isLoadingPreviewBatchDeleteFileBlob, data: batchUsers }] =
    usePreviewBatchDeleteFileBlobMutation();
  const [uploadBatchDeleteFileBlob, { isLoading: isLoadingUploadBatchDeleteFileBlob }] =
    useUploadBatchDeleteFileBlobMutation();
  const [base64Image, setBase64Image] = useState("");
  const [fileName, setFileName] = useState("");

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file && file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then(async (result) => {
        // console.log("get base64", result);
        setBase64Image(result);
        setFileName(file.name);

        await previewBatchDeleteFileBlob({
          fileName: file.name,
          serializedWord: result ? result.split(",")[1] : undefined,
          token: user.accessToken,
        });

        dispatch(
          showNotification({
            style: NOTIFICATION_STYLE.SUCCESS,
            message: `Batch upload delete user file telah disimpan`,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadClick = async () => {
    const users = get(batchUsers, "result");

    const { data } = await uploadBatchDeleteFileBlob({
      users: users,
      token: user.accessToken,
    });

    dispatch(
      showNotification({
        style: data.result.status === 'success' ? NOTIFICATION_STYLE.SUCCESS : NOTIFICATION_STYLE.ERROR,
        message: data.result.status !== 'failed' ? data.result.errMessage : `Batch delete user berhasil`,
      })
    );
  };
  
  return (
    <div className="grid-layout-container mt-2">
      <BlockUI blocking={(
        isLoadingPreviewBatchDeleteFileBlob || 
        isLoadingUploadBatchDeleteFileBlob 
      )} />
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 200,
          },
          {
            height: 750,
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <div className="px-11 mt-6">
            <div className="subtitle">
              <h3>Upload User</h3>
            </div>
            <div>
              <div className="row k-mb-4 items-center">
                <div className="col col-4">
                  <div className="k-label">Pilih file</div>
                </div>
                <div className="col col-8">
                <input
                    className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                    type="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".xls,.xlsx"
                  />
                </div>
              </div>

              <hr className="full-width" />
              
            </div>
          </div>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} className="grid-item clear-bg topbrr">
          <BatchDeleteListing 
            batchUsers={batchUsers} 
            handleUploadClick={handleUploadClick} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
