import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const analisisSoalSlice = createApi({
  reducerPath: 'analisisSoalApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/analisis-soal` }),
  tagTypes: ['analisis_soal_finder'],
  endpoints: (builder) => ({
    getPagingStatus: builder.query({
      query: ({ kodeSoal, paging = 100, token }) => ({
        url: `/search-get-pagination`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { paging, kodeSoal }
      }),
      providesTags: ['analisis_soal_finder'],
    }),
    getListSoal: builder.query({
      query: ({ kodeSoal, measure, infitMsnq, infitZstd, outfitMsnq, outfitZstd, ptMeasure, history, catatanAnalisis, paging = 100, page = 1, token }) => ({
        url: '/search-get-list',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { 
          paging, 
          page, 
          kodeSoal, 
          measure, 
          infitMsnq, 
          infitZstd, 
          outfitMsnq, 
          outfitZstd, 
          ptMeasure, 
          history, 
          catatanAnalisis 
        },
      }),
      invalidatesTags: ['analisis_soal_finder'],
    }),
    downloadAnalisisSoal: builder.mutation({
      queryFn: async ({ kodeSoal, paging = 100, page = 1, token, fileName }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: '/download-analisis-soal',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'POST',
          body: { kodeSoal, paging, page, },
          cache: 'no-cache',
          responseHandler: ((response) => response.blob())
        })
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobData = url.createObjectURL(result.data);
        hiddenElement.href = blobData;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null }
      }
    }),
    previewBatchFileBlob: builder.mutation({
      query: ({ fileName, serializedWord, token }) => ({
        url: '/preview-batch-upload-file',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { fileName, serializedWord },
      }),
      invalidatesTags: ['analisis_soal_uploader'],
    }),
    createAnalisisSoals: builder.mutation({
      query: ({ testName, analisisSoals, token }) => ({
        url: `/save-batch-upload-file`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          testName,
          analisisSoals,
        },
      }),
      providesTags: ['analisis_soal_uploader'],
    }),
  }),
});

export const {
  useGetPagingStatusQuery,
  useGetListSoalQuery,
  useDownloadAnalisisSoalMutation,
  usePreviewBatchFileBlobMutation,
  useCreateAnalisisSoalsMutation,
} = analisisSoalSlice;
