import { useState, useEffect, useCallback } from "react";
import { set, get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { Splitter, ExpansionPanel, ExpansionPanelContent, ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import { Reveal } from '@progress/kendo-react-animation';
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import useAuth from "../../common/hooks/useAuth";
import { isNullOrWhiteSpace } from "../../common/generic/GenericFunc";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import BlockUI from "../generic/BlockUI";
import { downloadIcon } from "@progress/kendo-svg-icons";
import {
  useGetPreviewKeysQuery,
  useGetSoalAttributeQuery,
  useRetrieveFileBlobMutation,
  useUpdateFileBlobMutation,
  useUpdateSoalAttributeMutation,
  useLazyGetPreviewKeysQuery
} from "../../features/soalEditorSlice";
import { useGetSettingQuery } from "../../features/settingSlice";
import { BentukSoal } from "../custom-control/custom-component";
import { FramePreview } from "./FramePreview";

export const DEFAULT_SOAL_BARU_NAME = "SOAL_BARU";

export const ExamFullDetail = ({ kodeSoal, toggleDialog }) => {
  // kodeSoal = "TES-SOAL-001";
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [retreiveFileBlob] = useRetrieveFileBlobMutation();
  const [updateFileBlob] = useUpdateFileBlobMutation();
  const [updateSoalAtribute] = useUpdateSoalAttributeMutation();
  const [getPreviewKey] = useLazyGetPreviewKeysQuery();
  const [base64Image, setBase64Image] = useState("");
  // const [imageExtension, setImageExtension] = useState("");
  const [soalOnSave, setSoalOnSave] = useState(false);
  const [previewKey, setPreviewKey] = useState();

  const [panes, setPanes] = useState([{ size: "99%", collapsible: true }, {}]);
  const onPaneChange = (event) => {
    setPanes(event.newState);
  };
  const [expanded, setExpanded] = useState(false);

  const userLevel = useSelector((state) => state.globalState.userLevel);


  // console.log("dari lockasi 4", { token: user.accessToken });
  const { data: getSettingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
      type: 'soal_creation',
    } : skipToken
  );

  const { data: settingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
    } : skipToken
  );

  const getPreviewKeyCallback = useCallback(async ({ kodeSoal, url }) => {
    const previewKeyFetch = await getPreviewKey({ kodeSoal, token: user.accessToken });
    let previewKey = { ...get(previewKeyFetch, 'data.result') };
    // const url = get(settingData, "mahir_preview_url", "");
    set(previewKey, 'url', url);
    console.log('set previewKey is', { kodeSoal, previewKey, settingData, url });
    setPreviewKey(previewKey);
  }, []);

  useEffect(() => {
    if (kodeSoal && kodeSoal.length > 3 && settingData && kodeSoal !== DEFAULT_SOAL_BARU_NAME) {
      getPreviewKeyCallback({ kodeSoal: kodeSoal, url: settingData.mahir_preview_url });
    }
  }, [kodeSoal, settingData]);


  const {
    data: soalPreviewKeys,
    // isLoading: getSoalHtmlLoading,
    isSuccess: getSoalPreviewKeysSuccess,
  } = useGetPreviewKeysQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken }
      : skipToken
  );

  const {
    data: soalAttribute,
    // isLoading: getSoalAttributeLoading,
    isSuccess: getSoalAttributeSuccess,
  } = useGetSoalAttributeQuery(
    kodeSoal && kodeSoal !== DEFAULT_SOAL_BARU_NAME
      ? { kodeSoal, token: user.accessToken }
      : skipToken
  );

  const handleDownloadClick = () => {
    // console.log("start download");
    retreiveFileBlob({
      kodeSoal: kodeSoal,
      fileName: `${kodeSoal}.docx`,
      token: user.accessToken,
    });
  };

  const handleSaveClick = async () => {
    setSoalOnSave(true);
    const attribute = {
      kodeSoal: kodeSoalValue,
      mataPelajaran: mataPelajaranValue,
      bentukSoal: bentukSoalValue,
      status: statusValue,
      kunci: kunciValue,
      tipeSoal: tipeSoalValue,
      kompetensi: kompetensiValue,
      subKompetensi: subKompetensiValue,
      indikator: indikatorValue,
      tingkatKesulitan: tingkatKesulitanValue,
      levelKognitif: levelKognitifValue,
      kelas: kelasValue,
      teknologi: teknologiValue,
      konten: kontenValue,
    };

    if (
      isNullOrWhiteSpace(kodeSoalValue) ||
      kodeSoalValue === DEFAULT_SOAL_BARU_NAME
    ) {
      return;
    }

    /* console.log("Save all click", {
      base64Image,
      imageExtension: base64Image ? base64Image.split(",")[1] : undefined,
      attribute,
    }); */

    await updateSoalAtribute({ attribute, token: user.accessToken });

    await updateFileBlob({
      kodeSoal,
      serializedWord: base64Image ? base64Image.split(",")[1] : undefined,
      token: user.accessToken,
    });

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Soal ${kodeSoalValue} telah disimpan`,
      })
    );

    setSoalOnSave(false);

    toggleDialog();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    // console.log("handleFileInputChange", { file });

    if (file.size > 5242835) {
      alert("file terlalu besar!");
      return;
    }

    getBase64(file)
      .then((result) => {
        // console.log("get base64", result);
        setBase64Image(result);
        // setImageExtension(file.name.split(".").pop());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [kodeSoalValue, setKodeSoalValue] = useState("");
  const handleKodeSoalChange = useCallback((event) => {
    setKodeSoalValue(event.value);
  }, []);

  const [mataPelajaranValue, setMataPelajaranValue] = useState("");
  const handleMataPelajaranChange = useCallback((event) => {
    setMataPelajaranValue(event.value);
  }, []);

  const [bentukSoalValue, setBentukSoalValue] = useState("");
  const handleBentukSoalChange = useCallback((event) => {
    setBentukSoalValue(event.value);
  }, []);

  const [statusValue, setStatusValue] = useState("");
  /*
  const handleStatusChange = useCallback((event) => {
    setStatusValue(event.value);
  }, []);
  */

  const [kunciValue, setKunciValue] = useState("");
  const handleKunciChange = useCallback((event) => {
    setKunciValue(event.value);
  }, []);

  const [kunciAppValue, setKunciAppValue] = useState("");
  const [tipeSoalValue, setTipeSoalValue] = useState("");
  /*
  const handleTipeSoalChange = useCallback((event) => {
    setTipeSoalValue(event.value);
  }, []);
  */

  const [kompetensiValue, setKompetensiValue] = useState("");
  const handleKompetensiChange = useCallback((event) => {
    setKompetensiValue(event.value);
  }, []);

  const [subKompetensiValue, setSubKompetensiValue] = useState("");
  const handleSubKompetensiChange = useCallback((event) => {
    setSubKompetensiValue(event.value);
  }, []);

  const [indikatorValue, setIndikatorValue] = useState("");
  const handleIndikatorChange = useCallback((event) => {
    // console.log("handleIndikatorChange", event.value);
    setIndikatorValue(event.value);
  }, []);

  const [tingkatKesulitanValue, setTingkatKesulitanValue] = useState("");
  const handleTingkatKesulitanChange = useCallback((event) => {
    setTingkatKesulitanValue(event.value);
  }, []);

  const [levelKognitifValue, setLevelKognitifValue] = useState("");
  const handleLevelKognitifChange = useCallback((event) => {
    setLevelKognitifValue(event.value);
  }, []);

  const [kelasValue, setKelasValue] = useState("");
  const handleKelasChange = useCallback((event) => {
    setKelasValue(event.value);
  }, []);

  const [teknologiValue, setTeknologiValue] = useState("");
  const handleTeknologiChange = useCallback((event) => {
    setTeknologiValue(event.value);
  }, []);

  const [kontenValue, setKontenValue] = useState("");
  const handleKontenChange = useCallback((event) => {
    setKontenValue(event.value);
  }, []);

  const [measureValue, setMeasureValue] = useState("");
  const [infitMsnqValue, setInfitMsnqValue] = useState("");
  const [infitZstdValue, setInfitZstdValue] = useState("");
  const [outfitMsnqValue, setOutfitMsnqValue] = useState("");
  const [outfitZstdValue, setOutfitZstdValue] = useState("");
  const [ptMeasureValue, setPtMeasureValue] = useState("");
  const [historyValue, setHistoryValue] = useState([]);
  const [catatanAnalisisValue, setCatatanAnalisisValue] = useState([]);

  useEffect(() => {
    if (
      soalAttribute &&
      soalAttribute.result &&
      getSoalAttributeSuccess &&
      kodeSoal !== DEFAULT_SOAL_BARU_NAME
    ) {
      // console.log("soalAttr", { soalAttribute });
      setKodeSoalValue(get(soalAttribute, "result.KodeSoal", ""));
      setMataPelajaranValue(get(soalAttribute, "result.MataPelajaran", ""));
      setBentukSoalValue(get(soalAttribute, "result.BentukSoal", ""));
      setStatusValue(get(soalAttribute, "result.Status", ""));
      setKunciValue(get(soalAttribute, "result.Kunci", ""));
      setKunciAppValue(get(soalAttribute, "result.KunciApp", ""));
      setTipeSoalValue(get(soalAttribute, "result.TipeSoal", ""));
      setKompetensiValue(get(soalAttribute, "result.Kompetensi", ""));
      setSubKompetensiValue(get(soalAttribute, "result.SubKompetensi", ""));
      setIndikatorValue(get(soalAttribute, "result.Indikator", ""));
      setTingkatKesulitanValue(
        get(soalAttribute, "result.TingkatKesulitan", "")
      );
      setLevelKognitifValue(get(soalAttribute, "result.LevelKognitif", ""));
      setKelasValue(get(soalAttribute, "result.Kelas", ""));
      setTeknologiValue(get(soalAttribute, "result.Teknologi", ""));
      setKontenValue(get(soalAttribute, "result.Konten", ""));

      setMeasureValue(get(soalAttribute, "result.Measure", ""));
      setInfitMsnqValue(get(soalAttribute, "result.InfitMsnq", ""));
      setInfitZstdValue(get(soalAttribute, "result.InfitZstd", ""));
      setOutfitMsnqValue(get(soalAttribute, "result.OutfitMsnq", ""));
      setOutfitZstdValue(get(soalAttribute, "result.OutfitZstd", ""));
      setPtMeasureValue(get(soalAttribute, "result.PtMeasure", ""));
      setHistoryValue(get(soalAttribute, "result.History", []));
      setCatatanAnalisisValue(get(soalAttribute, "result.CatatanAnalisis", ""));
    }
  }, [soalAttribute, getSoalAttributeSuccess, kodeSoal]);

  const hideAttribute =
    get(getSettingData, "hide_attribute", false) && userLevel >= 100;

  // console.log("Soal full Detail data", { soalHtml, soalAttribute });
  /*
  console.log("soalPreviewKeys", {
    soalPreviewKeys,
    getSoalPreviewKeysSuccess,
    userLevel,
    hideAttribute,
    bentukSoalValue,
  });
  */

  return (
    <Dialog
      className="dialog-soal-detail"
      title={" "}
      onClose={toggleDialog}
      width={"100vw"}
      height={"100vh"}
    >
      <div>
        <BlockUI
          blocking={
            !getSoalPreviewKeysSuccess || !soalPreviewKeys || soalOnSave
          }
        />
        <div className="">
          <Splitter panes={panes} onChange={onPaneChange}>
            <div className="pane-content p-4">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="subtitle">
                      <div className="preview-title">
                        <h3>Preview Soal</h3>
                        <div className="view-full">
                          <a
                            href={`/home/exam/preview/${kodeSoal}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="k-icon k-icon-xl k-i-zoom-actual-size" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      {getSoalPreviewKeysSuccess &&
                        soalPreviewKeys &&
                        soalPreviewKeys.result && (
                          <div className="iframe-view">
                            <BlockUI
                              blocking={!soalPreviewKeys.result}
                              className="w-100 h-100"
                            />
                            {(kodeSoal && kodeSoal.length > 3 && previewKey && settingData && kodeSoal !== DEFAULT_SOAL_BARU_NAME) && (
                              <FramePreview props={previewKey} />
                            )}
                            {/*
                            <iframe
                              className="w-100 h-100"
                              src={`${get(getSettingData, "mahir_preview_url", false)}/?viewkonten=${soalPreviewKeys.result.data1}&dataexp=${soalPreviewKeys.result.data2}&uid=${soalPreviewKeys.result.data3}`}
                              title="SoalPreview"
                            ></iframe>
                            */}

                          </div>
                          /*
                          <Editor
                            contentStyle={{
                              height: "50vh",
                            }}
                            value={soalHtml.result}
                          />
                          */
                        )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <hr className="full-width"></hr>
                    </div>
                  </div>
                  {/*
                  <div className="row mt-3 items-center">
                    <div className="col-2">
                      <div className="k-label">Upload Soal</div>
                    </div>
                    <div className="col-6">
                      <input
                        className="inputfile k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md w-100"
                        type="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".doc,.docx"
                      />
                    </div>
                  </div>
                      */}

                  {userLevel <= 100 && kodeSoal !== DEFAULT_SOAL_BARU_NAME && !hideAttribute && (
                    <div className="row mt-3 items-center">
                      <div className="col-2">
                        <div className="k-label">Download Soal</div>
                      </div>
                      <div className="col-6">
                        <Button
                          svgIcon={downloadIcon}
                          onClick={handleDownloadClick}
                          className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md w-100"
                        >
                          Download
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pane-content p-4">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="subtitle">
                      <div>
                        <h3>Atribut Soal</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row k-mb-4 items-center">
                    <div className="col col-4">
                      <div className="k-label">Kode Soal</div>
                    </div>
                    <div className="col col-8">
                      <Input
                        value={kodeSoalValue}
                        onChange={handleKodeSoalChange}
                        disabled={
                          userLevel >= 100 ||
                          kodeSoal !== DEFAULT_SOAL_BARU_NAME
                        }
                      />
                    </div>
                  </div>

                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Mata Pelajaran</div>
                      </div>
                      <div className="col col-8">
                        <Input
                          value={mataPelajaranValue}
                          onChange={handleMataPelajaranChange}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Bentuk Soal</div>
                      </div>
                      <div className="col col-8">
                        <BentukSoal xValue={bentukSoalValue}
                          xOnChange={handleBentukSoalChange}
                          xDisabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        ></BentukSoal>
                      </div>
                    </div>
                  )}

                  {/* <div className="row k-mb-4 items-center hide">
                    <div className="col col-4">
                      <div className="k-label">Status</div>
                    </div>
                    <div className="col col-8">
                      <NumericTextBox
                        value={statusValue}
                        onChange={handleStatusChange}
                        type={Number}
                        disabled={userLevel >= 100}
                      />
                    </div>
                  </div> */}

                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Kunci</div>
                      </div>
                      <div className="col col-8">
                        <Input
                          value={kunciValue}
                          onChange={handleKunciChange}
                          disabled={kodeSoal !== DEFAULT_SOAL_BARU_NAME}
                        />
                      </div>
                    </div>
                  )}

                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">System Key</div>
                      </div>
                      <div className="col col-8">
                        <div className="p-2 border rounded-md min-h-10">
                          {kunciAppValue}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="row k-mb-4 items-center">
                    <div className="col col-4">
                      <div className="k-label">Tipe Soal</div>
                    </div>
                    <div className="col col-8">
                      <Input
                        value={tipeSoalValue}
                        onChange={handleTipeSoalChange}
                        disabled={userLevel >= 100}
                      />
                    </div>
                  </div> */}

                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Kompetensi</div>
                      </div>
                      {kodeSoal === DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8">
                          <TextArea
                            value={kompetensiValue}
                            onChange={handleKompetensiChange}
                            disabled={userLevel >= 100}
                          />
                        </div>
                      )}
                      {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8 p-3">
                          <div className="p-2 border rounded-md min-h-10">
                            {kompetensiValue}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Sub Kompetensi</div>
                      </div>
                      {kodeSoal === DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8">
                          <TextArea
                            value={subKompetensiValue}
                            onChange={handleSubKompetensiChange}
                            disabled={userLevel >= 100}
                          />
                        </div>
                      )}
                      {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8 p-3">
                          <div className="p-2 border rounded-md min-h-10">
                            {subKompetensiValue}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Indikator</div>
                      </div>
                      {kodeSoal === DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8">
                          <TextArea
                            value={indikatorValue}
                            onChange={handleIndikatorChange}
                            disabled={userLevel >= 100}
                          />
                        </div>
                      )}
                      {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8 p-3 ">
                          <div className="p-2 border rounded-md min-h-10">
                            {indikatorValue}
                          </div>
                        </div>
                      )}

                      <div>

                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Tingkat Kesulitan</div>
                      </div>
                      <div className="col col-8">
                        <NumericTextBox
                          value={tingkatKesulitanValue}
                          onChange={handleTingkatKesulitanChange}
                          type={Number}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Level Kognitif</div>
                      </div>
                      <div className="col col-8">
                        <NumericTextBox
                          value={levelKognitifValue}
                          onChange={handleLevelKognitifChange}
                          type={Number}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Jenjang</div>
                      </div>
                      <div className="col col-8">
                        <NumericTextBox
                          value={kelasValue}
                          onChange={handleKelasChange}
                          type={Number}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Materi</div>
                      </div>
                      <div className="col col-8">
                        <Input
                          value={teknologiValue}
                          onChange={handleTeknologiChange}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!hideAttribute && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                        <div className="k-label">Konten</div>
                      </div>
                      {/*
                      <div className="col col-8">
                        <Input
                          value={kontenValue}
                          onChange={handleKontenChange}
                          disabled={
                            userLevel >= 100 ||
                            kodeSoal !== DEFAULT_SOAL_BARU_NAME
                          }
                        />
                      </div>
                      */}

                      {kodeSoal === DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8">
                          <TextArea
                            value={kontenValue}
                            onChange={handleKontenChange}
                            disabled={userLevel >= 100}
                          />
                        </div>
                      )}
                      {kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                        <div className="col col-8 p-3 ">
                          <div className="p-2 border rounded-md min-h-10">
                            {kontenValue}
                          </div>
                        </div>
                      )}


                    </div>
                  )}
                  {userLevel <= 100 && kodeSoal !== DEFAULT_SOAL_BARU_NAME && (
                    <div className="row k-mb-4 items-center">
                      <div className="col col-4">
                      </div>
                      <div className="col col-8 p-3 ">
                        <ExpansionPanel
                          title="Analisis"
                          expanded={expanded}
                          tabIndex={0}
                          onAction={(event) => {
                            setExpanded(!expanded)
                          }}
                        >
                          <Reveal>
                            {expanded && (
                              <ExpansionPanelContent>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>Measure&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{measureValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>InfitMsnq&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{infitMsnqValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>InfitZstd&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{infitZstdValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>OutfitMsnq&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{outfitMsnqValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>OutfitZstd&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{outfitZstdValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>PtMeasure&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{ptMeasureValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>History&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{historyValue}</Label></div>
                                </div>
                                <div className="row">
                                  <div className="col col-4">
                                    <Label>Catatan Analisis&nbsp;</Label>
                                  </div>
                                  <div className="col col-8"><Label>{catatanAnalisisValue}</Label></div>
                                </div>
                              </ExpansionPanelContent>
                            )}
                          </Reveal>
                        </ExpansionPanel>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Splitter>
          <div className="flex justify-end mt-2 mb-8"></div>
        </div>
      </div>
      <DialogActionsBar>
        {/*
        <button
          className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
          onClick={handleSaveClick}
          disabled={soalOnSave || !getSoalPreviewKeysSuccess}
        >
          Simpan
        </button>
                */}
        <button
          className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
          onClick={toggleDialog}
        >
          Tutup
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
