import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  trashIcon,
  zoomInIcon,
  trackChangesEnableIcon,
} from "@progress/kendo-svg-icons";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import {
  useGetListSoalQuery,
  useGetPagingStatusQuery,
} from "../../features/soalRevisiSlice";
import { useGetSettingQuery } from "../../features/settingSlice";
import { NumberCell } from "../custom-control/custom-cells";
import BlockUI from "../generic/BlockUI";
import { ExamFullDetail } from "../exam/ExamFullDetail";

export const Listing = () => {
  const { user } = useAuth();
  const PAGE_SIZE = 100;
  const [curentPageValue, setCurrentPageValue] = useState(1);
  const userLevel = useSelector((state) => state.globalState.userLevel);
  const [visible, setVisible] = useState(false);
  const [kodeSoalDetail, setKodeSoalDetail] = useState("");
  const handleOpenSoalDetailDialog = (dataItem) => {
    // console.log("handleOpenSoalDetailDialog", dataItem);
    setVisible(!visible);
    setKodeSoalDetail(dataItem.KodeSoal);
  };

  const handleToggleDialog = () => {
    setVisible(!visible);
  };

  const {
    data: getListSoalData,
    isFetching: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    // isError: isGetListSoalError,
    // error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken
      ? {
        page: curentPageValue,
        paging: PAGE_SIZE,
        token: user.accessToken,
      }
      : skipToken
  );

  // console.log("dari lokasi 3", { test: 'asdasd', token: user.accessToken });
  const { data: getSettingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
      type: 'soal_creation',
    } : skipToken
  );

  const {
    data: getPagingStatusData,
    isFetching: getPagingStatusLoading,
    isSuccess: isGetPagingStatusSuccess,
    // isError: isGetPagingStatusError,
    // error: getPagingStatusError,
  } = useGetPagingStatusQuery(
    user.accessToken
      ? {
        paging: PAGE_SIZE,
        token: user.accessToken,
        userId: user.uid,
      }
      : skipToken
  );

  useEffect(() => {
    //
  }, [curentPageValue]);

  const hideAttribute =
    get(getSettingData, "hide_attribute", false) && userLevel >= 100;

  /* console.log("Soal Revisi List result", {
    getPagingStatusData,
    getListSoalData,
  }); */

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    // console.log("page changes", { event: event.page, currPage });
  };

  const dateTimeCell = (props) => {
    let date = props.dataItem.ModifiedAt;
    const originalDate = new Date(date);

    // Extract the components of the date
    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();
    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");

    // Format the date into the desired format
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return <td>{formattedDate}</td>;
  };

  const statusSoalCell = (props) => {
    let statusSoal = props.dataItem.Status;
    /*
     * 0. Draft
     * 1. Revisi
     * 2. QC
     * 3. Published
     */
    let statusSoalText = "";
    switch (statusSoal) {
      case 0:
        statusSoalText = "Draft";
        break;
      case 1:
        statusSoalText = "Revisi";
        break;
      case 2:
        statusSoalText = "QC";
        break;
      case 3:
        statusSoalText = "Published";
        break;
      default:
        statusSoalText = "Draft";
    }

    return <td>{statusSoalText}</td>;
  };

  const tipeSoalCell = (props) => {
    let tipeSoal = props.dataItem.TipeSoal;
    /*
     * 1. PG - pilihan ganda
     * 2. PG Kompleks- pilihan ganda kompleks
     * 3. Cloze Procedure
     * 4. Essay
     * 5. Menjodohkan
     * 6. Highlight
     * 7. Highlight S
     * 8. Jawaban Tertutup
     * 9. Mix PG + Essay
     *10. Mix PGK + Essay
     *11. Mix JT + Essay
     *12. Highlight Select
     *13. Menjodohkan Line
     *14. Menjodohkan Flexibel Line
     */
    let tipeSoalText = "";
    switch (tipeSoal) {
      case "1":
        tipeSoalText = "Pilihan Ganda";
        break;
      case "2":
        tipeSoalText = "Pilihan Ganda Kompleks";
        break;
      case "3":
        tipeSoalText = "Cloze Procedure";
        break;
      case "4":
        tipeSoalText = "Essay";
        break;
      case "5":
        tipeSoalText = "Menjodohkan";
        break;
      case "6":
        tipeSoalText = "Highlight";
        break;
      case "7":
        tipeSoalText = "Highlight Single";
        break;
      case "8":
        tipeSoalText = "Jawaban Tertutup";
        break;
      case "9":
        tipeSoalText = "Mix PG + Essay";
        break;
      case "10":
        tipeSoalText = "Mix PGK + Essay";
        break;
      case "11":
        tipeSoalText = "Mix JT + Essay";
        break;
      case "12":
        tipeSoalText = "Highlight Select";
        break;
      case "13":
        tipeSoalText = "Menjodohkan Line";
        break;
      case "14":
        tipeSoalText = "Menjodohkan Flexibel Line";
        break;
      default:
        tipeSoalText = "Pilihan Ganda";
    }

    return <td>{tipeSoalText}</td>;
  };

  const actionCell = (props) => {
    // console.log("actionCell", props.dataItem);
    return (
      <td className="text-center">
        <Button
          className="action-btn"
          svgIcon={zoomInIcon}
          onClick={() => handleOpenSoalDetailDialog(props.dataItem)}
          title="View Details"
        ></Button>
        {!hideAttribute && (
          <Button
            className="action-btn"
            svgIcon={trackChangesEnableIcon}
            onClick={() =>
              window.open(
                `/home/exam/full-width/${props.dataItem.KodeSoal}?isedit=1`,
                "_blank"
              )
            }
            title="Edit in new tab"
          ></Button>
        )}
      </td>
    );
  };

  return (
    <div className="px-11 mt-6">
      <BlockUI blocking={getlistSoalLoading || getPagingStatusLoading} />
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {isGetListSoalSuccess &&
          getListSoalData &&
          getListSoalData.result &&
          isGetPagingStatusSuccess &&
          getPagingStatusData &&
          getPagingStatusData.result && (
            <Grid
              data={get(getListSoalData, "result", [])}
              total={get(getPagingStatusData, "result.TotalSoal", 0)}
              take={PAGE_SIZE}
              skip={(curentPageValue - 1) * PAGE_SIZE}
              pageable={{
                buttonCount: 10,
              }}
              onPageChange={pageChange}
              className="h-auto"
            >
              <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
              <GridColumn field="KodeSoal" title="Kode Soal" width={250} />
              <GridColumn
                field="MataPelajaran"
                title="Mata Pelajaran"
                width={150}
              />
              <GridColumn
                field="TipeSoal"
                title="Tipe Soal"
                cell={tipeSoalCell}
              />
              <GridColumn
                field="ModifiedAt"
                title="Tanggal Perubahan"
                cell={dateTimeCell}
              />
              <GridColumn field="Status" title="Status" cell={statusSoalCell} />
              <GridColumn
                field="KodeSoal"
                title="Action"
                cell={actionCell}
                width={100}
              />
            </Grid>
          )}

        {visible && (
          <ExamFullDetail
            kodeSoal={kodeSoalDetail}
            toggleDialog={handleToggleDialog}
          />
        )}
      </div>
    </div>
  );
};
