import {
  useState,
  useEffect,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { get } from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Label, Hint } from "@progress/kendo-react-labels";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useLazyQueryPaketQuery } from "../../features/soalPaketSlice";
import BlockUI from "../generic/BlockUI";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { importIcon, plusIcon, pencilIcon, gearsIcon } from "@progress/kendo-svg-icons";
import { NumberCell } from "../custom-control/custom-cells";
import moment from "moment";

export const Paket = forwardRef((props, _ref) => {
  let navigate = useNavigate();
  const [namaPaket, setNamaPaket] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");
  const [loading, setLoading] = useState(false);
  const [paketData, setPaketData] = useState([]);
  const [PaketQuery] = useLazyQueryPaketQuery();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [curentPageValue, setCurrentPageValue] = useState(1);

  const getPaket = useCallback(async (namaPaket, mataPelajaran) => {
    console.log('Get paket cuy', { namaPaket, mataPelajaran });
    setLoading(true);
    const paketQueryData = await PaketQuery({
      token: user.accessToken,
      namaPaket,
      mataPelajaran,
    });

    // console.log('get paket esult ', { paketQueryData });
    setPaketData(get(paketQueryData, 'data.result.Data.PaketSoals', []));
    setLoading(false);
  }, []);

  useEffect(() => {
    getPaket();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => getPaket(namaPaket, mataPelajaran), 1000);
    return () => clearTimeout(timeOutId);
  }, [namaPaket, mataPelajaran]);

  const actionCell = (props) => {
    // console.log("actionCell", props.dataItem);
    return (
      <td className="text-center">
        <Button
          className="action-btn"
          svgIcon={pencilIcon}
          onClick={() => navigate(`/home/paket/create/${props.dataItem.NamaPaket}`)}
        ></Button>
      </td>
    );
  };

  const renderContent = () => {
    if (paketData.length > 0) {
      return (
        <div className="px-11 mt-6">
          <BlockUI blocking={loading} />
          <Grid
            data={paketData}
            className="h-auto"
          >
            <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
            <GridColumn field="NamaPaket" title="Name Paket" />
            <GridColumn field="MataPelajaran" title="Mata Pelajaran" />
            <GridColumn title="Last Modified" cell={(props) => (
              <td>
                {moment(props.dataItem.ModifiedAt).format("DD-MMM-yyyy, hh:mm:ss")}
              </td>
            )} />
            <GridColumn
              field="NamaPaket"
              title="Action"
              cell={actionCell}
              width={100}
            />
          </Grid>
        </div>
      );
    }

  };

  return (
    <>
      <div className="px-11 mt-6 flex justify-between">
        <div>
        </div>
        <div>
          <Button
            svgIcon={plusIcon}
            onClick={() => navigate(`/home/paket/create`)}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
          >
            Create Paket
          </Button>
          <Button
            svgIcon={gearsIcon}
            onClick={() => navigate(`/home/paket/generate`)}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md ml-8"
          >
            Generate Paket
          </Button>
        </div>
      </div>
      <hr className="my-3"></hr>
      <div className="px-11 mt-6">
        <div className="subtitle">
          <h3>Paket Soal</h3>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Nama Paket</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setNamaPaket(e.target.value)} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setMataPelajaran(e.target.value)} />
          </div>
        </div>
      </div>
      {renderContent()}

    </>
  );
});
