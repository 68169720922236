import { get } from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { NumberCell } from "../custom-control/custom-cells";

export const BatchDeleteListing = ({ batchSoals, handleUploadClick }) => {
  return (
    <div className="px-11 mt-6">
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {batchSoals &&
          batchSoals.result && (
            <Grid
              data={get(batchSoals, "result")}
              className="h-auto"
            >
              <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
              <GridColumn field="KodeSoal" title="Kode Soal" width={250} />
            </Grid>
          )}
      </div>
      <div className="row k-mt-10">
        <div className="col col-12">
        {batchSoals &&
          batchSoals.result && (
            <div className="">
              <Button
                onClick={handleUploadClick}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
              >
                Upload
              </Button>
            </div>          
          )}
        </div>
      </div>
    </div>
  );
};
