import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const settingSlice = createApi({
  reducerPath: 'setting',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/setting` }),
  tagTypes: ['web_setting'],
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: ({ token, type = '' }) => ({
        url: `/${type}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['web_setting'],
    }),
    updateSetting: builder.mutation({
      query: ({ settingName, data, token }) => ({
        url: `/`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: { settingName, data },
      }),
      invalidatesTags: ['web_setting'],
    }),
  }),
});

export const {
  useGetSettingQuery,
  useLazyGetSettingQuery,
  useUpdateSettingMutation,
} = settingSlice;
