import {
  useState,
  useEffect,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { get } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Label, Hint } from "@progress/kendo-react-labels";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useGeneratePaketBaruMutation } from "../../features/soalPaketSlice";
import BlockUI from "../generic/BlockUI";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { chevronLeftIcon, gearsIcon } from "@progress/kendo-svg-icons";

export const Generate = forwardRef((props, _ref) => {
  let navigate = useNavigate();
  const [namaPaket, setNamaPaket] = useState("");
  const [formatKodeSoal, setFormatKodeSoal] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");
  const [jumlahSoal, setJumlahSoal] = useState("");
  const [acak, setAcak] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [soalGenerates, setSoalGenerates] = useState([]);
  const [generatePaketBaru] = useGeneratePaketBaruMutation();

  const [validNamaPaket, setValidNamaPaket] = useState(true);
  const [validFormat, setValidFormat] = useState(true);
  const [validMapel, setValidMapel] = useState(true);
  const [validJumlahSoal, setValidJumlahSoal] = useState(true);
  const { user } = useAuth();
  const dispatch = useDispatch();

  const handleStartGenerate = async (e) => {
    setIsLoading(true);
    setSoalGenerates([]);

    setValidNamaPaket(true);
    setValidFormat(true);
    setValidMapel(true);
    setValidJumlahSoal(true);
    var isFormValid = true;
    if (namaPaket.length < 3) {
      setValidNamaPaket(false);
      isFormValid = false;
    }
    if (formatKodeSoal.length < 3) {
      setValidFormat(false);
      isFormValid = false;
    }
    if (mataPelajaran.length < 3) {
      setValidMapel(false);
      isFormValid = false;
    }
    if (jumlahSoal < 1) {
      setValidJumlahSoal(false);
      isFormValid = false;
    }

    console.log("handle Start Generate", { namaPaket, acak, formatKodeSoal, mataPelajaran, jumlahSoal, isFormValid });
    if (!isFormValid) {
      setIsLoading(false);
      return;
    }


    var result = await generatePaketBaru({ namaPaket, format: formatKodeSoal, mataPelajaran, acak, jumlahSoal, token: user.accessToken });

    console.log("generate result", { result });

    if (get(result, "data.result.Status", -1) === 0) {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.SUCCESS,
          message: `Paket soal ${namaPaket} has been created`,
        })
      );
      setSoalGenerates(get(result, "data.result.Data", []));
    } else {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Failed to create Paket Soal - ${get(result, "data.result.Message", "")}`,
        })
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="px-11 mt-6 flex justify-between">
        <div>
          <Button
            svgIcon={chevronLeftIcon}
            onClick={() => navigate(`/home/paket`)}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
          >
          </Button>
        </div>
        <div>
          <Button
            svgIcon={gearsIcon}
            onClick={handleStartGenerate}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
          >
            Generate Soal
          </Button>

        </div>
      </div>
      <hr className="my-3"></hr>
      <div className="px-11 mt-6">
        <div className="subtitle">
          <h3>Generate Paket</h3>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Nama Paket</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setNamaPaket(e.target.value)} valid={validNamaPaket} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Format Kode Soal</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setFormatKodeSoal(e.target.value)} valid={validFormat} />
            <Hint direction={"start"}>
              <strong>%day</strong>: 2 digit hari |&nbsp;<strong>%month</strong>: 2 digit bulan |&nbsp;<strong>%year</strong>: 4 digit tahun |&nbsp;<strong>%no</strong>: urutan generate
            </Hint>
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setMataPelajaran(e.target.value)} valid={validMapel} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Acak</div>
          </div>
          <div className="col col-9">
            <Switch onChange={(e) => setAcak(e.target.value)} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Jumlah Soal</div>
          </div>
          <div className="col col-9">
            <NumericTextBox
              format={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
              placeholder=""
              onChange={e => setJumlahSoal(e.target.value)}
              valid={validJumlahSoal}
            />
          </div>
        </div>

      </div>


      <hr className="my-3"></hr>
      <div className="px-11 mt-6">
        <BlockUI
          blocking={isLoading}
        />
        <div className="subtitle">
          <h3>Hasil Generate</h3>
        </div>
        <div>

          <Grid
            data={soalGenerates}
            className="h-auto"
          >
            <GridColumn
              field="Key"
              title="Nama Soal"
              width={500}
              editable={false}
            />
            <GridColumn
              field="Value"
              title="Status"
              editable={false}
            />
          </Grid>

        </div>

      </div>
    </>
  );
});
