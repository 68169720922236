import {
  useState,
  useEffect,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { get } from 'lodash';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, Checkbox } from '@progress/kendo-react-inputs';
import { Label, Hint } from "@progress/kendo-react-labels";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useCreatePaketMutation, useLazyQueryPaketQuery, useDeletePaketMutation } from "../../features/soalPaketSlice";
import BlockUI from "../generic/BlockUI";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { chevronLeftIcon, chevronDoubleLeftIcon, chevronDoubleRightIcon, saveIcon, trashIcon } from "@progress/kendo-svg-icons";
import { useGetListSoalQuery, useGetPagingStatusQuery } from "../../features/soalFinderSlice";

export const Create = forwardRef((props, _ref) => {
  let navigate = useNavigate();
  const [createPaket] = useCreatePaketMutation();
  const [deletePaket] = useDeletePaketMutation();
  const [PaketQuery] = useLazyQueryPaketQuery();
  const PAGE_SIZE = 20;
  const [kodeSoal, setKodeSoal] = useState("");
  const [mataPelajaran, setMataPelajaran] = useState("");

  const [namaPaket, setNamaPaket] = useState("");
  const [paketMataPelajaran, setPaketMataPelajaran] = useState("");
  const [namaPaketValid, setNamaPaketValid] = useState(true);
  const [PaketMapelValid, setPaketMapelValid] = useState(true);

  const [curentPageValue, setCurrentPageValue] = useState(1);
  const [nonPaketSelected, setNonPaketSelected] = useState([]);
  const [paketSelected, setPaketSelected] = useState([]);
  const [paketList, setPaketList] = useState([]);

  const [visiblePopDelete, setVisiblePopDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();
  let { id } = useParams();

  const {
    data: getListSoalData,
    isFetching: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    isError: isGetListSoalError,
    error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken
      ? {
        kodeSoal: kodeSoal ?? "",
        mataPelajaran: mataPelajaran ?? "",
        page: curentPageValue,
        paging: PAGE_SIZE,
        token: user.accessToken
      }
      : skipToken
  );

  // console.log('Id is', { id });

  const setupExistingPaket = useCallback(async (namaPaket) => {
    console.log('Get paket cuy', { namaPaket });
    setIsLoading(true);
    const paketQueryData = await PaketQuery({
      token: user.accessToken,
      namaPaket,
      mataPelajaran: ''
    });

    console.log('get paket existing ', { paketQueryData });
    // setPaketData(get(paketQueryData, 'data.result.Data.PaketSoals', []));
    setNamaPaket(get(paketQueryData, 'data.result.Data.PaketSoals[0].NamaPaket', ''));
    setPaketMataPelajaran(get(paketQueryData, 'data.result.Data.PaketSoals[0].MataPelajaran', ''));
    setPaketList(get(paketQueryData, 'data.result.Data.PaketSoals[0].Soals', []))
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (id && id !== '') {
      setupExistingPaket(id);
    }
  }, []);


  const onCheckChange = (value, kodeSoal, type) => {
    console.log('onCheckChange', { value, kodeSoal, type });
    if (value) {
      if (type === 'nonpaket') {
        var newValue = [...nonPaketSelected, kodeSoal]
        setNonPaketSelected(newValue);
      } else {
        var newValue = [...paketSelected, kodeSoal]
        setPaketSelected(newValue);
      }
    } else {
      if (type === 'nonpaket') {
        var newValue = nonPaketSelected.filter(x => x !== kodeSoal);
        setNonPaketSelected(newValue);
      } else {
        var newValue = paketSelected.filter(x => x !== kodeSoal);
        setPaketSelected(newValue);
      }
    }

  };

  const nonPacketActionCell = (props) => {
    // console.log("action nonpaket Cell yy", props.dataItem);
    var isChecked = nonPaketSelected.some(x => x === props.dataItem.KodeSoal);
    return (
      <td className="text-center">
        <Checkbox value={isChecked} onChange={e => onCheckChange(e.target.value, props.dataItem.KodeSoal, 'nonpaket')} />
      </td>
    );
  };

  const packetActionCell = (props) => {
    // console.log("action paket Cell xx", props.dataItem);
    var isChecked = paketSelected.some(x => x === props.dataItem.kodeSoal);
    return (
      <td className="text-center">
        <Checkbox value={isChecked} onChange={e => onCheckChange(e.target.value, props.dataItem.kodeSoal, 'paket')} />
      </td>
    );
  };

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    console.log("page changes", { event: event.page, currPage });
  };

  const renderDaftarSoalNonPaket = () => {

    var nonPaketListData = get(getListSoalData, "result.Soals", []).filter(item => !paketList.includes(item.KodeSoal));
    var total = get(getListSoalData, "result.JumlahSoal", 0) - paketList.length;

    // console.log('render Daftar Soal NonPaket', { nonPaketListData, total, paketList, jumlah: get(getListSoalData, "result.JumlahSoal", 0), len: paketList.length });

    return (
      <>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Kode Soal</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setKodeSoal(e.target.value)} />
          </div>
        </div>

        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input placeholder="" onChange={e => setMataPelajaran(e.target.value)} />
          </div>
        </div>

        <div className="px-1 mt-6">
          <BlockUI blocking={isLoading} />
          <Grid
            data={nonPaketListData}
            total={total}
            take={PAGE_SIZE}
            skip={(curentPageValue - 1) * PAGE_SIZE}
            pageable={{ buttonCount: 10 }}
            onPageChange={pageChange}
            className="h-auto"
          >
            <GridColumn
              field="NamaPaket"
              title="Select"
              cell={nonPacketActionCell}
              width={80}
            />
            <GridColumn field="KodeSoal" title="Kode Soal" width={350} />
            <GridColumn field="MataPelajaran" title="Mata Pelajaran" width={100} />

          </Grid>

        </div>
      </>

    );

  };

  const renderDaftarSoalPaket = () => {
    // console.log('render paket list', { paketList });

    const paketListData = paketList.map((item, index) => ({
      id: index + 1,
      kodeSoal: item
    }));

    return (
      <>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Nama Paket</div>
          </div>
          <div className="col col-9">
            <Input value={namaPaket} placeholder="" onChange={e => setNamaPaket(e.target.value)} disabled={id !== undefined} valid={namaPaketValid} />
          </div>
        </div>
        <div className="row k-mb-4 items-center">
          <div className="col col-3">
            <div className="k-label">Paket Mata Pelajaran</div>
          </div>
          <div className="col col-9">
            <Input value={paketMataPelajaran} placeholder="" onChange={e => setPaketMataPelajaran(e.target.value)} valid={PaketMapelValid} />
          </div>
        </div>
        <div className="px-1 mt-6">
          <BlockUI blocking={isLoading} />
          <Grid
            data={paketListData}
            className="h-auto"
          >
            <GridColumn
              field="NamaPaket"
              title="Select"
              cell={packetActionCell}
              width={80}
            />
            <GridColumn field="kodeSoal" title="Kode Soal" width={350} />
          </Grid>

        </div>
      </>

    );
  };

  const handleAddSoal = async (e) => {
    setPaketList([...paketList, ...nonPaketSelected]);
    setNonPaketSelected([]);
  }

  const handleRemoveSoal = async (e) => {
    // console.log('Ceki cke', { nonPaketSelected, paketSelected, paketList });
    var newPaketList = paketList.filter(item => !paketSelected.includes(item));
    setPaketList(newPaketList);
    setPaketSelected([]);
  }

  const handleSimpanPaket = async (e) => {
    setIsLoading(true);
    console.log('simpan paket Handle', { namaPaket, mataPelajaran: paketMataPelajaran, soals: paketList });

    setNamaPaketValid(true);
    setPaketMapelValid(true);
    var isValidToSave = true;
    if (namaPaket.length < 3) {
      setNamaPaketValid(false);
      isValidToSave = false;
    }
    if (paketMataPelajaran.length < 1) {
      setPaketMapelValid(false);
      isValidToSave = false;
    }

    if (!isValidToSave) {
      setIsLoading(false);
      return;
    }

    var result = await createPaket({ namaPaket, mataPelajaran: paketMataPelajaran, soals: paketList, token: user.accessToken });

    console.log('handleSimpanPaket', { result });
    if (get(result, "data.result.Status", -1) === 0) {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.SUCCESS,
          message: `Paket soal ${namaPaket} has been created`,
        })
      );
      navigate(`/home/paket/create/${namaPaket}`);
    } else {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Failed to create Paket Soal - ${get(result, "data.result.Message", "")}`,
        })
      );
    }

    setIsLoading(false);

  }

  const handleHapusPaket = async (e) => {
    console.log('paket akan dihapus');
    setVisiblePopDelete(false);
    setIsLoading(true);
    var result = await deletePaket({ namaPaket, token: user.accessToken });

    console.log('handleSimpanPaket', { result });
    if (get(result, "data.result.Status", -1) === 0) {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.SUCCESS,
          message: `Paket soal ${namaPaket} has been removed`,
        })
      );
      navigate(`/home/paket`);
    } else {
      dispatch(
        showNotification({
          style: NOTIFICATION_STYLE.ERROR,
          message: `Failed to create Paket Soal - ${get(result, "data.result.Message", "")}`,
        })
      );
    }

  }

  return (
    <>
      <div className="px-11 mt-6 flex justify-between">
        <div>
          <Button
            svgIcon={chevronLeftIcon}
            onClick={() => navigate(`/home/paket`)}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
          >
          </Button>
        </div>
        <div>
          <Button
            svgIcon={saveIcon}
            onClick={() => handleSimpanPaket()}
            className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
          >
            Simpan
          </Button>
          <Button
            svgIcon={trashIcon}
            onClick={() => setVisiblePopDelete(true)}
            className="k-button k-button-md k-button-solid k-button-solid-error k-rounded-md ml-3"
          >
            Hapus
          </Button>

        </div>
      </div>


      <hr className="my-3"></hr>
      <div className="px-11 mt-6">
        <div className="subtitle">
          <h3>Create Paket Soal</h3>
        </div>

        <div className="row">
          <div className="col col-6 border-solid border rounded-lg p-3 setup-paket">
            {renderDaftarSoalNonPaket()}
          </div>
          <div className="col col-1 p-3 flex items-center justify-center flex-col">
            <div className="row">
              <Button
                svgIcon={chevronDoubleRightIcon}
                onClick={() => handleAddSoal()}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
              >
              </Button>
            </div>
            <div className="row mt-2">
              <Button
                svgIcon={chevronDoubleLeftIcon}
                onClick={() => handleRemoveSoal()}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
              >
              </Button>
            </div>
          </div>

          <div className="col col-5 border-solid border rounded-lg p-3 setup-paket">
            {renderDaftarSoalPaket()}
          </div>
        </div>

        {visiblePopDelete && (
          <Dialog title={"Konfirmasi"} onClose={() => setVisiblePopDelete(false)}>
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              Apakah anda ingin menghapus paket soal {namaPaket}
            </p>
            <DialogActionsBar>
              <Button type="button" onClick={() => setVisiblePopDelete(false)} className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md">
                No
              </Button>
              <Button type="button" onClick={() => handleHapusPaket()} className="k-button k-button-md k-button-solid k-button-solid-error k-rounded-md">
                Yes
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </>
  );
});
