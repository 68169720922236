import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, MaskedTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { saveIcon, cancelIcon } from "@progress/kendo-svg-icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { get, maxBy } from "lodash";
import { isNullOrWhiteSpace } from "../../common/generic/GenericFunc";
// import UserRole from "../../data/user-role";
import useAuth from "../../common/hooks/useAuth";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";
import { useLazyGetSettingQuery, useUpdateSettingMutation } from "../../features/settingSlice";
import { useGetUserQuery } from "../../features/userSlice";

export const Detail = () => {
  const DEFAULT_ID_TYPE = "KTP";
  const { user } = useAuth();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const userLevel = useSelector((state) => state.globalState.userLevel);
  const { data: userDetail } = useGetUserQuery(!isNullOrWhiteSpace(id) ? { token: user.accessToken, id } : skipToken);
  const [GetSetting] = useLazyGetSettingQuery();
  const [UpdateSetting, { isLoading: isUpdating }] = useUpdateSettingMutation();

  const [hideAttribute, setHideAttribute] = useState(false);
  const [mahirPreviewUrl, setMahirPreviewUrl] = useState('');
  const [validasiToken, setValidasiToken] = useState(false);
  const [validasiKunci, setValidasiKunci] = useState(false);

  const handleUpdateClick = async () => {
    console.log('porps to be save', { hideAttribute, mahirPreviewUrl, validasiToken, validasiKunci });
    await UpdateSetting({
      settingName: 'display',
      data: {
        hide_attribute: hideAttribute,
        mahir_preview_url: mahirPreviewUrl,
      },
      token: user.accessToken
    });

    await UpdateSetting({
      settingName: 'soal_creation',
      data: {
        validasi_token: validasiToken,
        validasi_kunci: validasiKunci
      },
      token: user.accessToken
    });

    fetchSettings();

    dispatch(
      showNotification({
        style: NOTIFICATION_STYLE.SUCCESS,
        message: `Setting telah disimpan`,
      })
    );

  }

  const fetchSettings = useCallback(async () => {
    const displaySetting = await GetSetting({
      token: user.accessToken,
      type: 'display',
    });

    const soalSetting = await GetSetting({
      token: user.accessToken,
      type: 'soal_creation',
    });

    console.log('setting is', { displaySetting, soalSetting });

    setHideAttribute(get(displaySetting, 'data.hide_attribute', false));
    setMahirPreviewUrl(get(displaySetting, 'data.mahir_preview_url', ''));
    setValidasiToken(get(soalSetting, 'data.validasi_token', false));
    setValidasiKunci(get(soalSetting, 'data.validasi_kunci', false));
  });

  useEffect(() => {
    fetchSettings();
  }, []);


  if (userLevel > get(userDetail, "result.user.role.level", 1000)) {
    return (
      <div className="px-11 mt-12">
        <span>Maaf User level anda tidak memungkinan melihat User Ini</span>
      </div>
    );
  } else {
    return (
      <div className="px-11 mt-12">
        <div>
          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Hide Attribute</div>
            </div>
            <div className="col col-8">
              <Checkbox
                defaultChecked={false}
                size={"large"}
                rounded={"large"}
                value={hideAttribute}
                onChange={(evt) => setHideAttribute(evt.target.value)}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Validasi Token</div>
            </div>
            <div className="col col-8">
              <Checkbox
                defaultChecked={false}
                size={"large"}
                rounded={"large"}
                value={validasiToken}
                onChange={(evt) => setValidasiToken(evt.target.value)}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Validasi Kunci</div>
            </div>
            <div className="col col-8">
              <Checkbox
                defaultChecked={false}
                size={"large"}
                rounded={"large"}
                value={validasiKunci}
                onChange={(evt) => setValidasiKunci(evt.target.value)}
              />
            </div>
          </div>

          <div className="row k-mb-4 items-center">
            <div className="col col-2">
              <div className="k-label">Mahir Preview Url</div>
            </div>
            <div className="col col-8">
              <Input
                value={mahirPreviewUrl}
                onChange={(evt) => setMahirPreviewUrl(evt.target.value)}
                placeholder="Mahir Preview Url"
              />
            </div>
          </div>

          <div className="row k-mb-4 k-mt-10 items-center">
            <div className="col col-2"></div>
            <div className="col col-8 text-right ">

              <Button
                svgIcon={saveIcon}
                onClick={handleUpdateClick}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
              >
                Update
              </Button>

            </div>
          </div>

        </div>
      </div>
    );
  }
};
