export const FramePreview = ({ props }) => {
  console.log('Soal iFramePreview data', { props });

  return (
    <>
      <iframe
        className="w-100 h-100"
        src={`${props.url}/?viewkonten=${props.data1
          }&dataexp=${props.data2}&uid=${props.data3
          }`}
        title="SoalPreview"
      ></iframe>
    </>
  );
};
