import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
// import { SoalRevisiPage } from "../components/exam/SoalRevisi";
import { Paket } from "../components/paket-soal/Paket";

export const PaketPage = () => {
  const isExpand = useSelector((state) => state.globalState.drawerExpand);

  return (
    <div
      className={
        isExpand ? "grid-layout-container expand" : "grid-layout-container"
      }
    >
      <GridLayout
        gap={{
          rows: 1,
          cols: 1,
        }}
        rows={[
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item clear-bg topbrr mb-8">
          <Paket />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
