import { useState, useEffect, useRef } from "react";
import { get } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { Link } from "react-router-dom";
import useAuth from "../../common/hooks/useAuth";
import BaseAvatar from "../../assets/wd.png";
import { Popup } from "@progress/kendo-react-popup";
import { ListView } from "@progress/kendo-react-listview";
import { setUserLevel, setRoleId } from "../../features/globalStateSlice";
import { useGetMeQuery } from "../../features/userSlice";

export const ProfileMenu = () => {
  const dispatch = useDispatch();
  const { user, logOut, refreshToken } = useAuth();
  const anchor_user_menu = useRef(null);
  const [show, setShow] = useState(false);
  const photoUrl = user && user.photoURL ? user.photoURL : BaseAvatar;

  const { data: userData } = useGetMeQuery(
    user.accessToken ? user.accessToken : skipToken
  );

  let menuProfileLinks = [
    {
      linkText: "Profile Pengguna",
      linkPath: `/home/manage-user/${user?.uid}`,
    },
    {
      linkText: "Setting",
      linkPath: "/home/settings",
    },
    {
      linkText: "Logout",
      linkPath: "",
    },
  ];

  useEffect(() => {
    if (user && userData && userData.result) {
      /* console.log("Profile Menu userData", {
        userData,
        level: get(userData, "result.user.role.level", 1000),
      }); */
      dispatch(setUserLevel(get(userData, "result.user.role.level", 1000)));
      dispatch(setRoleId(get(userData, "result.user.role.id", "X")));
    }
  }, [user, userData]);

  const MyItemRender = (props) => {
    let item = props.dataItem;
    // console.log("MyItemRender item", { item });
    return (
      <>
        {item.linkText === "Logout" && (
          <Link
            to="#"
            className="k-listview-item row align-middle menu-profile-item-container"
            onClick={logOut}
          >
            {item.linkText}
          </Link>
        )}
        {item.linkText !== "Logout" && (
          <Link
            to={item.linkPath}
            className="k-listview-item row align-middle menu-profile-item-container"
            onClick={() => setShow(!show)}
          >
            {item.linkText}
          </Link>
        )}
      </>
    );
  };

  const onClick = () => {
    setShow(!show);
  };

  /*
  console.log('User Detail', {
    user,
    userexp: user.stsTokenManager.expirationTime,
    timenow: moment().unix(),
    timeUserExp: moment(user.stsTokenManager.expirationTime).unix()
  })
  */

  if (user.stsTokenManager.expirationTime) {
    if (moment().unix() > moment(user.stsTokenManager.expirationTime).unix()) {
      console.log("Token Force Refresh");
      refreshToken();
    }
  }

  return (
    <div>
      <div
        className={show ? "button-menu-profile active" : "button-menu-profile"}
        onClick={onClick}
        ref={anchor_user_menu}
      >
        <div
          className="img"
          style={{ backgroundImage: `url(${photoUrl})` }}
        ></div>
        <div className="text">
          <span>{user?.displayName}</span>
        </div>
      </div>
      <Popup
        anchor={anchor_user_menu.current}
        show={show}
        popupClass={"popup-content"}
        className="menu-profile-dropdown-wrapper z-50"
        style={{ zIndex: 400 }}
      >
        <ListView
          className="menu-profile-dropdown-item w-full"
          data={menuProfileLinks}
          item={MyItemRender}
        />
      </Popup>
    </div>
  );
};
