import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Filter } from "../components/analisis-soal/Filter";
import { Listing } from "../components/analisis-soal/Listing";

export const SoalAnalisis = () => {
  const myLevel = useSelector((state) => state.globalState.userLevel);
  const isExpand = useSelector((state) => state.globalState.drawerExpand);

  const [startFiltering, setStartFiltering] = useState(false);
  const [filterParam, setFilterParam] = useState({});
  const handleStartFiltering = (e) => {
    setFilterParam({
      kodeSoal: kodeSoal,
      measure: measure,
      infitMsnq: infitMsnq,
      infitZstd: infitZstd,
      outfitMsnq: outfitMsnq,
      outfitZstd: outfitZstd,
      ptMeasure: ptMeasure,
      history: history,
      catatanAnalisis: catatanAnalisis,
    });
    setStartFiltering(!startFiltering);
  };

  const [kodeSoal, setKodeSoal] = useState("");
  const [measure, setMeasure] = useState();
  const [infitMsnq, setInfitMsnq] = useState();
  const [infitZstd, setInfitZstd] = useState();
  const [outfitMsnq, setOutfitMsnq] = useState();
  const [outfitZstd, setOutfitZstd] = useState();
  const [ptMeasure, setPtMeasure] = useState();
  const [history, setHistory] = useState();
  const [catatanAnalisis, setCatatanAnalisis] = useState();
  const updateKodeSoal = (e) => {
    setKodeSoal(e.target.value);
  };
  const updateMeasure = (e) => {
    setMeasure(e.target.value);
  };
  const updateInputMsnq = (e) => {
    setInfitMsnq(e.target.value);
  };
  const updateInfitZstd = (e) => {
    setInfitZstd(e.target.value);
  };
  const updateOutfitMsnq = (e) => {
    setOutfitMsnq(e.target.value);
  };
  const updateOutfitZstd = (e) => {
    setOutfitZstd(e.target.value);
  };
  const updatePtMeasure = (e) => {
    setPtMeasure(e.target.value);
  };
  const updateHistory = (e) => {
    setHistory(e.target.value);
  };
  const updateCatatanAnalisis = (e) => {
    setCatatanAnalisis(e.target.value);
  };
  
  useEffect(() => {
    // console.log('something changes', {kodeSoal, mataPelajaran, indikator});
    const timeOutId = setTimeout(() => handleStartFiltering(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [kodeSoal, measure, infitMsnq, infitZstd, outfitMsnq, outfitZstd, ptMeasure, history, catatanAnalisis]);

  if (myLevel > 100) {
    return (
      <div className="grid-layout-container mt-2 p-8">
        Maaf Anda Tidak deperkenankan melihat halaman ini
      </div>
    );
  }

  return (
    <div
      className={
        isExpand
          ? "grid-layout-container mt-2 mb-36 expand"
          : "grid-layout-container mt-2 mb-36"
      }
    >
      <GridLayout
        gap={{
          rows: 2,
          cols: 1,
        }}
        rows={[
          {
            height: 450,
          },
          {
            height: "auto",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1} className="grid-item">
          <Filter
            updateKodeSoal={updateKodeSoal}
            updateMeasure={updateMeasure}
            updateInputMsnq={updateInputMsnq}
            updateInfitZstd={updateInfitZstd}
            updateOutfitMsnq={updateOutfitMsnq}
            updateOutfitZstd={updateOutfitZstd}
            updatePtMeasure={updatePtMeasure}
            updateHistory={updateHistory}
            updateCatatanAnalisis={updateCatatanAnalisis}
          />
        </GridLayoutItem>
        <GridLayoutItem
          row={2}
          col={1}
          className="grid-item clear-bg topbrr mb-8"
        >
          <Listing startFiltering={startFiltering} filterParam={filterParam} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};
