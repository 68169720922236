import { useEffect, useState } from "react";
import { get } from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { NumberCell } from "../custom-control/custom-cells";

const editField = "inEdit";

export const BatchUploadListing = ({ testName, batchAnalisisSoals, handleUploadClick }) => {
  const { user } = useAuth();
  const PAGE_SIZE = 10;
  // soal and user selection
  const [editID, setEditID] = useState(null);
  const [analisisSoals, setAnalisisSoals] = useState(get(batchAnalisisSoals, "result"));
  const [curentPageValue, setCurrentPageValue] = useState(1);

  useEffect(() => {
    const tempBatchSoals = get(batchAnalisisSoals, "result");
    
    if (tempBatchSoals) {
      const newData = tempBatchSoals.map((item) => ({
        ...item,
        inEdit: item.KodeSoal === editID,
      }))

      setAnalisisSoals(newData);
    }
    
  }, [batchAnalisisSoals]);

  useEffect(() => {
    if (analisisSoals && analisisSoals.length !== 0) {
      const newData = analisisSoals.map((item) => ({
        ...item,
        inEdit: item.KodeSoal === editID,
      }));
      setAnalisisSoals(newData);
    }
  }, [editID]);

  const enterEdit = (event) => {
    setEditID(event.dataItem.KodeSoal);
  };

  const itemChange = (event) => {
    const inEditID = event.dataItem.KodeSoal;
    const field = event.field || "";
    const newData = analisisSoals.map((item) =>
      item.KodeSoal === inEditID
        ? {
            ...item,
            [field]: field == "CatatanAnalisis" ? event.value : parseInt(event.value),
          }
        : item
    );
    setAnalisisSoals(newData);
  };

  const exitEdit = (event) => {
    setEditID(null);
  };

  const handleModifiedAnalisisSoalClick = async () => {
    handleUploadClick(analisisSoals);
  };

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    exitEdit();
  };

  return (
    <div className="px-11 mt-6 pb-6">
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {analisisSoals && analisisSoals.length && (
          <Grid 
            data={analisisSoals.slice((curentPageValue - 1) * PAGE_SIZE, PAGE_SIZE + (curentPageValue - 1) * PAGE_SIZE)}
            onRowClick={enterEdit}
            onItemChange={itemChange}
            onPageChange={pageChange}
            editField={editField}
            total={analisisSoals.length ?? 0}
            take={PAGE_SIZE}
            skip={(curentPageValue - 1) * PAGE_SIZE}
            pageable={{
              buttonCount: 10,
            }}
            className="h-auto"
          >
            <GridColumn
              cells={{ data: NumberCell }}
              title="No."
              width={50}
              editable={false}
            />
            <GridColumn
              field="KodeSoal"
              title="Kode Soal"
              width={250}
              editable={false}
            />
            <GridColumn
              field="Measure"
              title="Measure"
            />
            <GridColumn
              field="InfitMsnq"
              title="InfitMsnq"
            />
            <GridColumn
              field="InfitZstd"
              title="InfitZstd"
            />
            <GridColumn
              field="OutfitMsnq"
              title="OutfitMsnq"
            />
            <GridColumn
              field="OutfitZstd"
              title="OutfitZstd"
            />
            <GridColumn
              field="PtMeasure"
              title="PtMeasure"
            />
            <GridColumn
              field="CatatanAnalisis"
              title="CatatanAnalisis"
            />
          </Grid>
        )}
      </div>
      <div className="row k-mt-10">
        <div className="col col-12">
          {batchAnalisisSoals && batchAnalisisSoals.result && (
            <div className="">
              <Button
                disabled={testName === ""}
                onClick={handleModifiedAnalisisSoalClick}
                className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1"
              >
                Upload
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
