import { Input } from "@progress/kendo-react-inputs";
import { NumericTextBox } from '@progress/kendo-react-inputs';


export const Filter = ({
  updateKodeSoal,
  updateMeasure, 
  updateInputMsnq, 
  updateInfitZstd, 
  updateOutfitMsnq, 
  updateOutfitZstd, 
  updatePtMeasure, 
  updateHistory, 
  updateCatatanAnalisis
}) => {
  return (
    <div className="px-11 mt-6">
      <div className="subtitle">
        <h3>Filter Soal</h3>
      </div>
      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">Kode Soal</div>
        </div>
        <div className="col col-9">
          <Input placeholder="" onChange={updateKodeSoal} />
        </div>
      </div>

      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">Measure</div>
        </div>
        <div className="col col-9">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }} 
            placeholder="" 
            onChange={updateMeasure}
          />
        </div>
      </div>

      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">InfitMsnq</div>
        </div>
        <div className="col col-3">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}  
            placeholder="" onChange={updateInputMsnq} 
          />
        </div>
        <div className="col col-3">
          <div className="k-label">InfitZstd</div>
        </div>
        <div className="col col-3">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            placeholder="" 
            onChange={updateInfitZstd} 
          />
        </div>
      </div>

      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">OutfitMsnq</div>
        </div>
        <div className="col col-3">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            placeholder="" 
            onChange={updateOutfitMsnq} 
          />
        </div>
        <div className="col col-3">
          <div className="k-label">OutfitZstd</div>
        </div>
        <div className="col col-3">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            placeholder="" 
            onChange={updateOutfitZstd} 
          />
        </div>
      </div>

      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">PtMeasure</div>
        </div>
        <div className="col col-9">
          <NumericTextBox
            format={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            placeholder="" 
            onChange={updatePtMeasure} 
          />
        </div>
      </div>

      <div className="row k-mb-4 items-center">
        <div className="col col-3">
          <div className="k-label">History</div>
        </div>
        <div className="col col-3">
          <Input placeholder="" onChange={updateHistory} />
        </div>
        <div className="col col-3">
          <div className="k-label">Catatan Analisis</div>
        </div>
        <div className="col col-3">
          <Input placeholder="" onChange={updateCatatanAnalisis} />
        </div>
      </div>
    </div>
  );
};
