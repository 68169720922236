import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { zoomInIcon, trackChangesEnableIcon, trashIcon } from "@progress/kendo-svg-icons";
import { get } from "lodash";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import {
  useGetListSoalQuery,
  useGetPagingStatusQuery,
  useDeleteSoalMutation,
} from "../../features/soalFinderSlice";

import { useGetSettingQuery } from "../../features/settingSlice";
import { NumberCell } from "../custom-control/custom-cells";
import BlockUI from "../generic/BlockUI";
import { ExamFullDetail } from "../exam/ExamFullDetail";

export const Listing = ({ startFiltering, filterParam }) => {
  const { user } = useAuth();
  const PAGE_SIZE = 100;
  const [curentPageValue, setCurrentPageValue] = useState(1);
  const userLevel = useSelector((state) => state.globalState.userLevel);

  const [deleteSoalModalVisible, setDeleteSoalModalVisible] = useState(false);
  const [detailSoalModalVisible, setDetailSoalModalVisible] = useState(false);
  const [kodeSoalDetail, setKodeSoalDetail] = useState("");
  const handleOpenSoalDetailDialog = (dataItem) => {
    // console.log("handleOpenSoalDetailDialog", dataItem);
    setDetailSoalModalVisible(!detailSoalModalVisible);
    setKodeSoalDetail(dataItem.KodeSoal);
  };

  const toggleDeleteSoalDialog = (dataItem) => {
    var toggled = !deleteSoalModalVisible;
    setDeleteSoalModalVisible(toggled);

    if (toggled) {
      setKodeSoalDetail(dataItem != null ? dataItem.KodeSoal : '');
    } else {
      setKodeSoalDetail();
    }
  };

  const confirmRemove = (kodeSoal) => {
    removeSoal({
      id: kodeSoal,
      token: user.accessToken,
    });
    setDeleteSoalModalVisible(!deleteSoalModalVisible);
    setKodeSoalDetail();
  };

  const handleToggleDialog = () => {
    setDetailSoalModalVisible(!detailSoalModalVisible);
  };

  const {
    data: getListSoalData,
    isFetching: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    isError: isGetListSoalError,
    error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken
      ? {
        kodeSoal: filterParam.kodeSoal ?? "",
        mataPelajaran: filterParam.mataPelajaran ?? "",
        indikator: filterParam.indikator ?? "",
        page: curentPageValue,
        paging: PAGE_SIZE,
        token: user.accessToken,
        userId: user.uid,
      }
      : skipToken
  );

  const { data: getSettingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
      type: 'soal_creation',
    } : skipToken
  );
  /*
    const {
      data: getPagingStatusData,
      isFetching: getPagingStatusLoading,
      isSuccess: isGetPagingStatusSuccess,
      isError: isGetPagingStatusError,
      error: getPagingStatusError,
    } = useGetPagingStatusQuery(
      user.accessToken
        ? {
            kodeSoal: filterParam.kodeSoal ?? "",
            mataPelajaran: filterParam.mataPelajaran ?? "",
            indikator: filterParam.indikator ?? "",
            paging: PAGE_SIZE,
            token: user.accessToken,
            userId: user.uid,
          }
        : skipToken
    );
    */

  const [removeSoal] = useDeleteSoalMutation();

  useEffect(() => {
    //
  }, [curentPageValue]);

  useEffect(() => {
    if (curentPageValue !== 1) {
      setCurrentPageValue(1);
    }
  }, [startFiltering]);

  const hideAttribute =
    get(getSettingData, "hide_attribute", false) && userLevel >= 100;

  /*
  console.log("Soal List result", {
    getPagingStatusData,
    getListSoalData,
    filterParam,
    startFiltering,
    userLevel,
    getSettingData,
    hideAttribute,
  });
  */

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    console.log("page changes", { event: event.page, currPage });
  };

  const dateTimeCell = (props) => {
    let date = props.dataItem.ModifiedAt;
    const originalDate = new Date(date);

    // Extract the components of the date
    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();
    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");

    // Format the date into the desired format
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return <td>{formattedDate}</td>;
  };

  const statusCell = (props) => {
    let status = props.dataItem.Status;
    /*
    1.Draft
    2.Revisi
    3.QC
    4.Published
    */
    let statusText = "";
    switch (status) {
      case 1:
        statusText = "Draft";
        break;
      case 2:
        statusText = "Revisi";
        break;
      case 3:
        statusText = "QC";
        break;
      case 4:
        statusText = "Published";
        break;
      default:
        statusText = "Draft";
    }

    return <td>{statusText}</td>;
  };

  const statusSoalCell = (props) => {
    let statusSoal = props.dataItem.Status;
    /*
     * 0. Draft
     * 1. Revisi
     * 2. QC
     * 3. Published
     */
    let statusSoalText = "";
    switch (statusSoal) {
      case 0:
        statusSoalText = "Draft";
        break;
      case 1:
        statusSoalText = "Revisi";
        break;
      case 2:
        statusSoalText = "QC";
        break;
      case 3:
        statusSoalText = "Published";
        break;
      default:
        statusSoalText = "Draft";
    }

    return <td>{statusSoalText}</td>;
  };

  const tipeSoalCell = (props) => {
    let tipeSoal = props.dataItem.TipeSoal;
    /*
     * 1. PG - pilihan ganda
     * 2. PG Kompleks- pilihan ganda kompleks
     * 3. Cloze Procedure
     * 4. Essay
     * 5. Menjodohkan
     * 6. Highlight
     * 7. Highlight S
     * 8. Jawaban Tertutup
     * 9. Mix PG + Essay
     *10. Mix PGK + Essay
     *11. Mix JT + Essay
     *12. Highlight Select
     *13. Menjodohkan Line
     *14. Menjodohkan Flexibel Line
     */
    let tipeSoalText = "";
    switch (tipeSoal) {
      case "1":
        tipeSoalText = "Pilihan Ganda";
        break;
      case "2":
        tipeSoalText = "Pilihan Ganda Kompleks";
        break;
      case "3":
        tipeSoalText = "Cloze Procedure";
        break;
      case "4":
        tipeSoalText = "Essay";
        break;
      case "5":
        tipeSoalText = "Menjodohkan";
        break;
      case "6":
        tipeSoalText = "Highlight";
        break;
      case "7":
        tipeSoalText = "Highlight Single";
        break;
      case "8":
        tipeSoalText = "Jawaban Tertutup";
        break;
      case "9":
        tipeSoalText = "Mix PG + Essay";
        break;
      case "10":
        tipeSoalText = "Mix PGK + Essay";
        break;
      case "11":
        tipeSoalText = "Mix JT + Essay";
        break;
      case "12":
        tipeSoalText = "Highlight Select";
        break;
      case "13":
        tipeSoalText = "Menjodohkan Line";
        break;
      case "14":
        tipeSoalText = "Menjodohkan Flexibel Line";
        break;
      default:
        tipeSoalText = "Pilihan Ganda";
    }

    return <td>{tipeSoalText}</td>;
  };

  const actionCell = (props) => {
    // console.log("actionCell", props.dataItem);
    return (
      <td className="text-center">
        <Button
          className="action-btn"
          svgIcon={zoomInIcon}
          onClick={() => handleOpenSoalDetailDialog(props.dataItem)}
          title="View Details"
        ></Button>
        {!hideAttribute && (
          <Button
            className="action-btn"
            svgIcon={trackChangesEnableIcon}
            onClick={() =>
              window.open(
                `/home/exam/full-width/${props.dataItem.KodeSoal}?isedit=1`,
                "_blank"
              )
            }
            title="Edit in new tab"
            display=""
          ></Button>
        )}
        <Button
          className="action-btn"
          svgIcon={trashIcon}
          onClick={() => toggleDeleteSoalDialog(props.dataItem)}
          title="Delete"
        ></Button>
      </td>
    );
  };

  return (
    <div className="px-11 mt-6">
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        <BlockUI blocking={getlistSoalLoading} />
        {isGetListSoalSuccess &&
          getListSoalData &&
          getListSoalData.result && (
            // isGetPagingStatusSuccess &&
            // getPagingStatusData &&
            // getPagingStatusData.result && (
            <Grid
              data={get(getListSoalData, "result.Soals", [])}
              total={get(getListSoalData, "result.JumlahSoal", 0)}
              take={PAGE_SIZE}
              skip={(curentPageValue - 1) * PAGE_SIZE}
              pageable={{
                buttonCount: 10,
              }}
              onPageChange={pageChange}
              className="h-auto"
            >
              <GridColumn cells={{ data: NumberCell }} title="No." width={50} />
              <GridColumn field="KodeSoal" title="Kode Soal" width={250} />
              <GridColumn
                field="MataPelajaran"
                title="Mata Pelajaran"
                width={150}
              />
              <GridColumn
                field="TipeSoal"
                title="Tipe Soal"
                cell={tipeSoalCell}
              />
              <GridColumn
                field="ModifiedAt"
                title="Tanggal Perubahan"
                cell={dateTimeCell}
              />
              <GridColumn field="Status" title="Status" cell={statusSoalCell} />
              {userLevel < 100 && (
                <GridColumn
                  field="KodeSoal"
                  title="Action"
                  cell={actionCell}
                  width={128}
                  visible={false}
                />
              )}
            </Grid>
          )}

        {detailSoalModalVisible && (
          <ExamFullDetail
            kodeSoal={kodeSoalDetail}
            toggleDialog={handleToggleDialog}
          />
        )}
        {deleteSoalModalVisible && (
          <Dialog title={" "} onClose={() => toggleDeleteSoalDialog(kodeSoalDetail)}>
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              Are you sure you want to delete {kodeSoalDetail}?
            </p>
            <DialogActionsBar>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => toggleDeleteSoalDialog(kodeSoalDetail)}
              >
                No
              </button>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => confirmRemove(kodeSoalDetail)}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </div>
  );
};
