import { useState, useEffect } from "react";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../generic/BlockUI";
import { NumberCell } from "../custom-control/custom-cells";
import { 
  useGetPagingStatusQuery, 
  useGetListSoalQuery, 
  useDownloadAnalisisSoalMutation
} from "../../features/analisisSoalSlice";
import { showNotification } from "../../features/globalStateSlice";
import { NOTIFICATION_STYLE } from "../generic/wd-notification";

export const Listing = ({ startFiltering, filterParam }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const PAGE_SIZE = 10;
  const [curentPageValue, setCurrentPageValue] = useState(1);
  
  const [pageProgress, setPageProgress] = useState(false);

  const {
    data: getListSoalData,
    isFetching: getlistSoalLoading,
    isSuccess: isGetListSoalSuccess,
    isError: isGetListSoalError,
    error: getListSoalError,
  } = useGetListSoalQuery(
    user.accessToken
      ? {
          kodeSoal: filterParam.kodeSoal ?? "",
          measure: filterParam.measure,
          infitMsnq: filterParam.infitMsnq,
          infitZstd: filterParam.infitZstd,
          outfitMsnq: filterParam.outfitMsnq,
          outfitZstd: filterParam.outfitZstd,
          ptMeasure: filterParam.ptMeasure,
          history: filterParam.history,
          catatanAnalisis: filterParam.catatanAnalisis,
          page: curentPageValue,
          paging: PAGE_SIZE,
          token: user.accessToken,
          userId: user.uid,
        }
      : skipToken
  );

  const {
    data: getPagingStatusData,
    isFetching: getPagingStatusLoading,
    isSuccess: isGetPagingStatusSuccess,
    isError: isGetPagingStatusError,
    error: getPagingStatusError,
  } = useGetPagingStatusQuery(
    user.accessToken
      ? {
          kodeSoal: filterParam.kodeSoal ?? "",
          measure: filterParam.measure,
          infitMsnq: filterParam.infitMsnq,
          infitZstd: filterParam.infitZstd,
          outfitMsnq: filterParam.outfitMsnq,
          outfitZstd: filterParam.outfitZstd,
          ptMeasure: filterParam.ptMeasure,
          history: filterParam.history,
          catatanAnalisis: filterParam.catatanAnalisis,
          paging: PAGE_SIZE,
          token: user.accessToken,
          userId: user.uid,
        }
      : skipToken
  );

  const [downloadAnalisisSoal] = useDownloadAnalisisSoalMutation();

  useEffect(() => {
    //
  }, [curentPageValue, startFiltering]);

  const pageChange = (event) => {
    const currPage = event.page.skip / PAGE_SIZE + 1;
    setCurrentPageValue(currPage);
    // console.log("page changes", { event: event.page, currPage });
  };

  const handleDownloadClick = async () => {
    setPageProgress(true);

    var total = get(getPagingStatusData, "result.TotalSoal", 0);
    
    await downloadAnalisisSoal({ 
      kodeSoal: filterParam.kodeSoal ?? "",
      measure: filterParam.measure,
      infitMsnq: filterParam.infitMsnq,
      infitZstd: filterParam.infitZstd,
      outfitMsnq: filterParam.outfitMsnq,
      outfitZstd: filterParam.outfitZstd,
      ptMeasure: filterParam.ptMeasure,
      history: filterParam.history,
      catatanAnalisis: filterParam.catatanAnalisis,
      fileName: 'AnalisisSoal.xlsx', 
      page: 1,
      paging: total,
      token: user.accessToken,
      userId: user.uid,
    });

    setPageProgress(false);
  };

  return (
    <div className="px-11 mt-6">
      <BlockUI
        blocking={
          getlistSoalLoading ||
          getPagingStatusLoading ||
          // getUsersLoading ||
          // createAlokasiSoalUserLoading ||
          pageProgress
        }
      />
      <div className="subtitle">
        <h3>Daftar Soal</h3>
      </div>
      <div>
        {isGetListSoalSuccess &&
          getListSoalData &&
          getListSoalData.result &&
          isGetPagingStatusSuccess &&
          getPagingStatusData &&
          getPagingStatusData.result && (
            <Grid
              data={get(getListSoalData, "result", [])}
              total={get(getPagingStatusData, "result.TotalSoal", 0)}
              take={PAGE_SIZE}
              skip={(curentPageValue - 1) * PAGE_SIZE}
              pageable={{
                buttonCount: 10,
              }}
              onPageChange={pageChange}
              className="h-auto"
            >
              <GridColumn
                cells={{ data: NumberCell }}
                title="No."
                width={50}
                editable={false}
              />
              <GridColumn
                field="KodeSoal"
                title="Kode Soal"
                width={250}
                editable={false}
              />
              <GridColumn
                field="Measure"
                title="Measure"
                editable={false}
              />
              <GridColumn
                field="InfitMsnq"
                title="InfitMsnq"
                editable={false}
              />
              <GridColumn
                field="InfitZstd"
                title="InfitZstd"
                editable={false}
              />
              <GridColumn
                field="OutfitMsnq"
                title="OutfitMsnq"
                editable={false}
              />
              <GridColumn
                field="OutfitZstd"
                title="OutfitZstd"
                editable={false}
              />
              <GridColumn
                field="PtMeasure"
                title="PtMeasure"
                editable={false}
              />
              <GridColumn
                field="CatatanAnalisis"
                title="CatatanAnalisis"
                editable={false}
              />
            </Grid>
          )}
      </div>
      <div className="row k-mt-10">
        <div className="col col-12">
          {isGetListSoalSuccess &&
            getListSoalData &&
            getListSoalData.result &&
            getPagingStatusData &&
            getPagingStatusData.result && (
              <div className="">
                <Button
                  onClick={handleDownloadClick}
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md px-5 mx-1 mb-12"
                >
                  Download
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
