import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { get, set } from "lodash";
import useAuth from "../../common/hooks/useAuth";
import BlockUI from "../../components/generic/BlockUI";
import { useLazyGetPreviewKeysQuery } from "../../features/soalEditorSlice";
import { useGetSettingQuery } from "../../features/settingSlice";
import { FramePreview } from "./FramePreview";
import { DEFAULT_SOAL_BARU_NAME } from "./ExamFullDetail";

export const SoalPreview = (props) => {
  // const isExpand = useSelector((state) => state.globalState.drawerExpand);
  // let { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [getPreviewKey] = useLazyGetPreviewKeysQuery();
  const [previewKey, setPreviewKey] = useState();

  // var kodeSoal = props.kodeSoal;
  // console.log("dari lockasi 1", { token: user.accessToken });
  const { data: settingData } = useGetSettingQuery(
    user.accessToken ? {
      token: user.accessToken,
    } : skipToken
  );

  const getPreviewKeyCallback = useCallback(async ({ kodeSoal, url }) => {
    const previewKeyFetch = await getPreviewKey({ kodeSoal, token: user.accessToken });
    let previewKey = { ...get(previewKeyFetch, 'data.result') };
    // const url = get(settingData, "mahir_preview_url", "");
    set(previewKey, 'url', url);
    // console.log('set previewKey is', { kodeSoal, previewKey, settingData, url });
    setPreviewKey(previewKey);
  }, []);

  useEffect(() => {
    if (props.kodeSoal && props.kodeSoal.length > 3 && props.kodeSoal !== DEFAULT_SOAL_BARU_NAME && settingData) {
      getPreviewKeyCallback({ kodeSoal: props.kodeSoal, url: settingData.mahir_preview_url });
    }
  }, [props.kodeSoal, settingData]);

  console.log('Soal Preview data', { settingData, previewKey, props, kodeSoal: props.kodeSoal });
  let canPreview = props.kodeSoal && props.kodeSoal.length > 3 && props.kodeSoal !== DEFAULT_SOAL_BARU_NAME && previewKey && settingData && previewKey.data1;

  return (
    <>
      <BlockUI blocking={!previewKey} className="w-100 h-100" />
      {canPreview && (
        <FramePreview props={previewKey} />
      )}
      {!canPreview && (
        <div className="flex justify-center items-center h-100">
          <h2>Maaf Preview gagal</h2>
        </div>
      )}
    </>
  );
};
