import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { drawerExpand, fullWidthModeEventChanges, noHeaderModeEventChanges } from "../features/globalStateSlice";
import { SoalPreview } from '../components/exam/SoalPreview';

export const SoalPreviewPage = () => {
  // const isExpand = useSelector((state) => state.globalState.drawerExpand);
  let { id } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(drawerExpand(true));
    dispatch(fullWidthModeEventChanges(true));
    dispatch(fullWidthModeEventChanges(true));
    dispatch(noHeaderModeEventChanges(true));
  }, []);

  // console.log('Soal Preview data', { id });

  let canPreview = id && id.length > 3;

  return (
    <>
      {(canPreview) && (
        <SoalPreview kodeSoal={id} />
      )}

      {(!canPreview) && (
        <div className="flex justify-center items-center h-100">
          <h2>Maaf Preview gagal</h2>
        </div>
      )}
    </>
  );
};
