import {
  useState,
  useEffect,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { get } from "lodash";
import useAuth from "../../common/hooks/useAuth";
import { columns } from "../soal-revisi-kanban/settings";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
  useGetAllUsersQuery,
  useLazyGetUserQuery,
} from "../../features/userSlice";
import { renderPhoto } from "../custom-control/custom-component";

export const Assignee = forwardRef((props, _ref) => {
  const { user } = useAuth();
  const [GetUserQuery] = useLazyGetUserQuery();
  const [values, setValues] = useState([]);

  const {
    data: usersData,
    isFetching: getUsersLoading,
    isSuccess: isGetUsersSuccess,
    isError: isGetUsersError,
    error: getUsersError,
  } = useGetAllUsersQuery(
    user.accessToken ? { currPage: -1, token: user.accessToken } : skipToken
  );

  useImperativeHandle(_ref, () => ({
    getAssignee: () => {
      return values;
    },
  }));

  const assignInitialAssignee = useCallback(async (usersAlloc) => {
    // console.log("assignInitialAssignee props", { props, usersAlloc });
    // console.log("allSoal", allSoal);
    const assignees = [];
    for (const userAlloc of usersAlloc) {
      const userDto = await GetUserQuery({
        token: user.accessToken,
        id: userAlloc.UserId,
      });
      // console.log("userDto", { userDto });
      assignees.push(get(userDto, 'data.result.user'));
    }

    const existingValues = [];
    for (const column of columns.filter((x) => x.needAssignee)) {
      const assigneeInRole = assignees.filter(
        (x) => get(x, 'role.id') === column.allowedRoles[0]
      );
      if (assigneeInRole.length > 0) {
        existingValues.push({
          ...column,
          ...assigneeInRole[0],
        });
      }
    }

    // console.log("assignees", { assignees, columns, existingValues });
    setValues(existingValues);
  }, []);

  useEffect(() => {
    if (
      get(usersData, "result.users", []).length > 0 &&
      values &&
      values.length === 0 &&
      get(props, "usersAlloc", []).length > 0
    ) {
      // console.log("props page", { props });
      assignInitialAssignee(get(props, "usersAlloc", []));
    }
  }, [usersData, props.usersAlloc]);

  const itemRender = (li, itemProps) => {
    // console.log("item X Render", { itemProps, li });
    const itemChildren = (
      <div className="user-item-list">
        {renderPhoto({
          photoURL: itemProps.dataItem.photoURL,
          displayName: itemProps.dataItem.displayName,
        })}
        <span className="user-name">{itemProps.dataItem.displayName}</span>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element, selectedValue, column) => {
    /*
    console.log("valueRender element", {
      selectedValue,
      element,
      values,
      column,
    });
    */
    if (selectedValue) {
      return (
        <div className="user-item-list k-input-inner">
          {renderPhoto({
            photoURL: selectedValue.photoURL,
            displayName: selectedValue.displayName,
          })}
          <span className="user-name">{selectedValue.displayName}</span>
        </div>
      );
    } else {
      if (values.filter((x) => x.id === column.id).length > 0) {
        return <span className="k-input-inner">{element}</span>;
      } else {
        return (
          <span className="k-input-inner">
            <input placeholder="Please select ..." value="" />
          </span>
        );
      }
    }
  };

  const onChange = (event, column) => {
    // console.log("onChange event", { event, column });

    const newValues = [];
    if (event.value === null) {
      newValues.push(...values.filter((x) => x.id !== column.id));
    } else {
      for (const column of columns) {
        if (column.status === get(event, "value.status")) {
          newValues.push({ ...event.value });
        } else {
          const existingValue = values.filter(
            (x) => x.status === column.status
          );
          if (existingValue.length > 0) {
            newValues.push({ ...existingValue[0] });
          }
        }
      }
    }

    // console.log("onChange newValues", { newValues });
    setValues(newValues);
    if (props.assigneeChange) {
      props.assigneeChange({ ...event, data: newValues });
    }
  };

  const renderAssigneeColumn = () => {
    return columns
      .filter((x) => x.needAssignee)
      .map((column) => {
        const usersRoleData = [];

        for (const userData of get(usersData, "result.users", [])) {
          const usersInRole = column.allowedRoles.filter(
            (x) => x === get(userData, "role.id", "X")
          );
          if (usersInRole.length > 0) {
            usersRoleData.push({ ...userData, ...column });
          }
        }

        const selectedValue =
          values.filter((x) => x.status === column.status).length > 0
            ? values.filter((x) => x.status === column.status)[0]
            : undefined;

        // console.log("usersData", { column, usersRoleData, selectedValue });

        return (
          <div
            className={props.vertical ? "col" : "col col-4"}
            key={column.status}
          >
            <div className=" progress-item">
              <div className="progress-label">{column.title}</div>
              <div className="progress-content">
                <ComboBox
                  data={usersRoleData}
                  itemRender={itemRender}
                  allowCustom={true}
                  textField="displayName"
                  dataItemKey="uid"
                  value={selectedValue}
                  onChange={(e) => onChange(e, column)}
                  valueRender={(element) =>
                    valueRender(element, selectedValue, column)
                  }
                  placeholder="Please select ..."
                  style={{
                    width: "98%",
                  }}
                />
              </div>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      {usersData && usersData.result && (
        <div
          className={
            props.vertical
              ? "k-mb-4 items-center progress-style p-1 vertical "
              : "row k-mb-4 items-center progress-style"
          }
        >
          {renderAssigneeColumn()}
        </div>
      )}
    </>
  );
});
